.var {}

// Fonts
$fontSizexSmall:  10px;
$fontSizeSmall:   13px;
$fontSize:        15px;
$fontSizeLarge:   20px;
$fontSizexLarge:  24px;
$fontHeaderSize:  38px;
$fontPrimary:     system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
//$fontPrimary:     'Nunito', 'Helvetica Neue', 'Lucida Grande', Arial, Verdana, sans-serif;
$fontHeader:      $fontPrimary;
$fontLight:       lighter;
$fontNormal:      normal;
$fontMedium:      500;
$fontBold:        bold;
$fontStyle:       normal;
$letterSpacing:   0;
$lineHeight:      1.47;

// Sizes
$sidebarSize:     265px;
$navSize:         65px;
$navSizeMobile:   54px;
$logoSize:        50px;
$logoSizeMobile:  36px;
$padding-xs:      5px;
$padding-sm:      2*$padding-xs;
$padding:         3*$padding-xs;
$padding-md:      4*$padding-xs;
$padding-lg:      5*$padding-xs;
$padding-xl:      6*$padding-xs;

// Bootstrap Grid Breakpoints
$grid-xs:         0;
$grid-sm:         544px;
$grid-md:         768px;
$grid-lg:         992px;
$grid-xl:         1200px;
$grid-xxl:        1400px;

$inputSizeXl:     80px;
$inputSizeLg:     60px;
$inputSize:       50px;
$inputSizeMd:     44px;
$inputSizeSm:     40px;
$inputSizeXs:     35px;
$inputSizeXxs:    14px;

// Colors
$blue:            #2962FF;
$red:             #d93025;
$yellow:          #f1c40f;
$green:           #2ecc71;
$orange:          #d35400;

$textColor:       #37474f;
$textColorLight:  #76838f;
$headerColor:     #1D2435;
$textColorDark:   #2c398f;

$white:           #FFFFFF;
$bodyColor:       #F5F7FA;
$bodyColorDark:   #F4F6F8;
$bodyColorLight:  #f7f7f7;
$borderColor:     rgba(0,0,0,0.1);
$borderColorLight:rgba(0,0,0,0.065);
$borderColorDark: rgba(0,0,0,0.25);
$frameColor:      #E5F5FE;
$overlayColor:    rgba(255,255,255,0.5);

// Brand Colors
$primaryColor:    $blue;
$secondaryColor:  $red;
$tirtiaryColor:   $green;

// Sidebar Colors
$sidebarDark:     #1C2A36;
$sidebarDarkDropdown: #131e26;

// Forms Colors
$greenAlert:      #6FC080;
$redAlert:        #DD6777;
$blueAlert:       #6EC7E6;
$yellowAlert:     #FFCE44;
$ltorange:        lighten($secondaryColor, 20%);
$ltgreen:         #3bffb2;
$pinkAlert:       #FAC0C0;

$boxShadow:       0 2px 5px 0 rgba(62,57,107,.1);
$boxShadowLg:     0 10px 45px 0 rgba(7,61,125,0.10);
$boxShadowSolid:  4px 4px 0px rgba(34, 45, 125, 0.1);
$boxShadowHover:  0 14px 24px rgba(62,57,107,.1);
$boxShadowCard:   rgba(45,45,45,0.05) 0px 2px 2px, rgba(49,49,49,0.05) 0px 4px 4px, rgba(42,42,42,0.05) 0px 8px 8px, rgba(32,32,32,0.05) 0px 16px 16px, rgba(49,49,49,0.05) 0px 32px 32px;

// Social Media Brand Colors
$twitter:         #00aced;
$facebook:        #3b5998;
$googleplus:      #dd4b39;
$pinterest:       #cb2027;
$linkedin:        #007bb6;
$youtube:         #bb0000;
$vimeo:           #aad450;
$tumblr:          #32506d;
$instagram:       #517fa4;
$flickr:          #ff0084;
$dribbble:        #ea4c89;
$soundcloud:      #ff3a00;
$behance:         #1769ff;

// Animation Speed
$animateFast: 0.2s;
$animateNormal: 0.3s;
$animateSlow: 0.6s;
$t: 0.3s;
