/*
* Theme: Bootadmin
* Author: Sazzad Hossain
* Author URL: http://sazzad.me
*/

/** Fonts **/

/** Assets **/
@import "asset/variables";
@import "asset/mixins";
@import "base";
@import "classhelper";

/** Loader **/
@import "components/loader";

/** Navigation **/
@import "components/topbar";
@import "components/sidebar";

/** Components **/
@import "components/cards";
@import "components/gallery";
@import "components/icons";
@import "components/breadcrumb";
@import "components/pagination";
@import "components/forms";
@import "components/tables";
@import "components/calendar";
@import "components/tree";

/** Pages **/
@import "pages/login";
@import "pages/error";

/** Layout **/
@import "layout";

.app {
    $b: &;

    &-email {
        .list-group {
            .list-group-item {
                padding: 0;
                border: none;
                background: transparent;

                &.list-group-item-title {
                    padding: $padding-sm $padding;
                    background: transparent;
                    font-weight: $fontBold;
                }

                a {
                    padding: $padding-sm $padding;
                    background: transparent;

                    &:hover {
                        background: white;
                    }
                }

                &.active a {
                    background: white;
                    @include box-shadow($boxShadow);
                }
            }
        }
    }
}

.note-color-row {
    width: 160px;
}

.dropdown-item pre {
    padding: 0;
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    font-family: monospace;
    font-size: $fontSize;
}

.note-editor.note-frame {
    border-color: $borderColor;
}




/** Page Styles **/
.page {
    $b: &;

    &-breadcrumb {
        //background: white;
        padding: $padding;

        h1, h2, h3, h4, h5 {
            margin: 0 0 $padding-xs;
        }

        .page-title {
            font-size: $fontSizeLarge;
            margin: 0 0 $padding-xs;
        }

        .breadcrumb {
            background: transparent;
            padding: 0;
            margin: 0;
            font-size: $fontSizeSmall;
        }
    }
}

/** Modal **/
.modal-content {
    border: none;
    @include border-radius(0);
    @include box-shadow($boxShadowHover);

    .modal-title {
        font-size: $fontSizeLarge;
        font-weight: $fontNormal;
    }
}

/** Footer **/
footer {
    text-align: center;
    padding: $padding-xl $padding;

    p {
        font-size: $fontSizeSmall;
    }
}

/** Demo Content **/
.grid-demo {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: rgba(86,61,124,.15);
    border: 1px solid rgba(86,61,124,.2);
}

@media (max-width: ($grid-md - 1px)) {
    main {
        padding: 0 0 $navSizeMobile !important;
    }

    .sidebar-open .sidebar {
        position: fixed;
        width: calc(100% - 30px);
        height: 94%;
        bottom: 0;
        top: auto;
        left: 15px;
        overflow-y: auto;
        @include transition(.2s all ease);
    }

    .sidebar-closed .sidebar:not(:hover), .sidebar-closed .sidebar {
        height: 0;
        width: calc(100% - 30px);
        bottom: 0;
        top: auto;
        left: 15px;
        @include transition(.2s all ease);
    }

    .hidden-xs {
        display: none !important;
    }
}
