#error {
    min-height: calc(100vh - 180px);
    height: 80vh;

    .error-box {
        max-width: 800px;
        text-align: center;
        padding: $padding-xl $padding-xl 2*$padding-xl;
        margin: 0 auto;
        background: white;
        @include box-shadow($boxShadowLg);
        @include border-radius(4px);
        @include vertical-align();

        .error-image {
            display: block;
            text-align: center;
            margin-bottom: 2rem;

            img {
                max-width: 100%;
            }
        }

        h1 {
            font-size: 2rem;
        }
    }
}
