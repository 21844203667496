.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
	th, td {
		padding: 0.75rem;
		vertical-align: top;
		border-top: 1px solid #e9ecef;
	}
	thead th {
		vertical-align: bottom;
		border-bottom: 2px solid #e9ecef;
	}
	tbody + tbody {
		border-top: 2px solid #e9ecef;
	}
	.table {
		background-color: #dee2e6;
	}
}
.table-sm {
	th, td {
		padding: 0.3rem;
	}
}
.table-bordered {
	border: 1px solid #e9ecef;
	th, td {
		border: 1px solid #e9ecef;
	}
	thead {
		th, td {
			border-bottom-width: 2px;
		}
	}
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: $bodyColor;
}
.table-hover tbody tr:hover {
	background-color: rgba(0, 0, 0, 0.075);
}
.table-primary {
	background-color: #b8daff;
	> {
		th, td {
			background-color: #b8daff;
		}
	}
}
.table-hover .table-primary:hover {
	background-color: #9fcdff;
	> {
		td, th {
			background-color: #9fcdff;
		}
	}
}
.table-secondary {
	background-color: #d9cfea;
	> {
		th, td {
			background-color: #d9cfea;
		}
	}
}
.table-hover .table-secondary:hover {
	background-color: #cbbde2;
	> {
		td, th {
			background-color: #cbbde2;
		}
	}
}
.table-success {
	background-color: #c3e6cb;
	> {
		th, td {
			background-color: #c3e6cb;
		}
	}
}
.table-hover .table-success:hover {
	background-color: #b1dfbb;
	> {
		td, th {
			background-color: #b1dfbb;
		}
	}
}
.table-info {
	background-color: #b8e7fc;
	> {
		th, td {
			background-color: #b8e7fc;
		}
	}
}
.table-hover .table-info:hover {
	background-color: #a0dffb;
	> {
		td, th {
			background-color: #a0dffb;
		}
	}
}
.table-warning {
	background-color: #ffeeba;
	> {
		th, td {
			background-color: #ffeeba;
		}
	}
}
.table-hover .table-warning:hover {
	background-color: #ffe8a1;
	> {
		td, th {
			background-color: #ffe8a1;
		}
	}
}
.table-danger {
	background-color: #f5c6cb;
	> {
		th, td {
			background-color: #f5c6cb;
		}
	}
}
.table-hover .table-danger:hover {
	background-color: #f1b0b7;
	> {
		td, th {
			background-color: #f1b0b7;
		}
	}
}
.table-light {
	background-color: #f9fafb;
	> {
		th, td {
			background-color: #f9fafb;
		}
	}
}
.table-hover .table-light:hover {
	background-color: #eaedf1;
	> {
		td, th {
			background-color: #eaedf1;
		}
	}
}
.table-dark {
	background-color: #bcbcbc;
	> {
		th, td {
			background-color: #bcbcbc;
		}
	}
}
.table-hover .table-dark:hover {
	background-color: #afafaf;
	> {
		td, th {
			background-color: #afafaf;
		}
	}
}
.table-active {
	background-color: rgba(0, 0, 0, 0.075);
	> {
		th, td {
			background-color: rgba(0, 0, 0, 0.075);
		}
	}
}
.table-hover .table-active:hover {
	background-color: rgba(0, 0, 0, 0.075);
	> {
		td, th {
			background-color: rgba(0, 0, 0, 0.075);
		}
	}
}
.thead-inverse th {
	color: #dee2e6;
	background-color: #111111;
}
.thead-default th {
	color: #495057;
	background-color: #e9ecef;
}
.table-inverse {
	color: #dee2e6;
	background-color: #111111;
	th, td, thead th {
		border-color: #242424;
	}
	&.table-bordered {
		border: 0;
	}
	&.table-striped tbody tr:nth-of-type(odd) {
		background-color: $bodyColor;
	}
	&.table-hover tbody tr:hover {
		background-color: rgba(255, 255, 255, 0.075);
	}
}
@media (max-width: 991px) {
	.table-responsive {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		&.table-bordered {
			border: 0;
		}
	}
}
