.btn-circle {
    width: $inputSizeSm;
    height: $inputSizeSm;
    line-height: $inputSizeSm;
    padding: 0;
    margin-right: $padding-sm;
    text-align: center;
    @include border-radius(50%);
}

.input-group {
    position: relative;
    display: flex;
    width: 100%;
    .form-control {
        position: relative;
        z-index: 2;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        &:focus, &:active, &:hover {
            z-index: 3;
        }
    }
}
.input-group-addon, .input-group-btn, .input-group .form-control {
    display: flex;
    align-items: center;
}
.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.input-group-addon, .input-group-btn {
    white-space: nowrap;
    vertical-align: middle;
}
.input-group-addon {
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    color: #343a40;
    text-align: center;
    background-color: #e9ecef;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 0.35rem;
    &.form-control-sm {
        padding: 0.25rem 1.25rem;
        font-size: 0.875rem;
        border-radius: 0.3rem;
    }
}
.input-group-sm > {
    .input-group-addon, .input-group-btn > .input-group-addon.btn {
        padding: 0.25rem 1.25rem;
        font-size: 0.875rem;
        border-radius: 0.3rem;
    }
}
.input-group-addon.form-control-lg {
    padding: 1rem 2.25rem;
    font-size: 1.25rem;
    border-radius: 0.4rem;
}
.input-group-lg > {
    .input-group-addon, .input-group-btn > .input-group-addon.btn {
        padding: 1rem 2.25rem;
        font-size: 1.25rem;
        border-radius: 0.4rem;
    }
}
.input-group-addon input {
    &[type="radio"], &[type="checkbox"] {
        margin-top: 0;
    }
}
.input-group .form-control:not(:last-child), .input-group-addon:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-group-btn {
    &:not(:last-child) > {
        .btn, .btn-group > .btn, .dropdown-toggle {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    &:not(:first-child) > {
        .btn:not(:last-child):not(.dropdown-toggle), .btn-group:not(:last-child) > .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
.input-group-addon:not(:last-child) {
    border-right: 0;
}
.input-group .form-control:not(:first-child), .input-group-addon:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.input-group-btn {
    &:not(:first-child) > {
        .btn, .btn-group > .btn, .dropdown-toggle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    &:not(:last-child) > {
        .btn:not(:first-child), .btn-group:not(:first-child) > .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}
.form-control + .input-group-addon:not(:first-child) {
    border-left: 0;
}
.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    > .btn {
        position: relative;
        + .btn {
            margin-left: -1px;
        }
        &:focus, &:active, &:hover {
            z-index: 3;
        }
    }
    &:not(:last-child) > {
        .btn, .btn-group {
            margin-right: -1px;
        }
    }
    &:not(:first-child) > {
        .btn, .btn-group {
            z-index: 2;
            margin-left: -1px;
        }
        .btn {
            &:focus, &:active {
                z-index: 3;
            }
        }
        .btn-group {
            &:focus, &:active {
                z-index: 3;
            }
        }
        .btn:hover, .btn-group:hover {
            z-index: 3;
        }
    }
}

/** Custom Controls **/
.custom-control {
    position: relative;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked ~ .custom-control-indicator {
        color: #fff;
        background-color: #007bff;
        box-shadow: none;
    }
    &:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 1px #dee2e6,0 0 0 3px #007bff;
    }
    &:active ~ .custom-control-indicator {
        color: #fff;
        background-color: #b3d7ff;
        box-shadow: none;
    }
    &:disabled ~ {
        .custom-control-indicator {
            background-color: #e9ecef;
        }
        .custom-control-description {
            color: #868e96;
        }
    }
}
.custom-control-indicator {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    user-select: none;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    box-shadow: inset 0 0.25rem 0.25rem rgba(0,0,0,0.1);
}
.custom-checkbox {
    .custom-control-indicator {
        border-radius: 0.35rem;
    }
    .custom-control-input {
        &:checked ~ .custom-control-indicator {
            background-image: url("data:image/svg+xml;
            charset=utf8,%3Csvg xmlns='http: //www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        }
        &:indeterminate ~ .custom-control-indicator {
            background-color: #007bff;
            background-image: url("data:image/svg+xml;
            charset=utf8,%3Csvg xmlns='http: //www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
            box-shadow: none;
        }
    }
}
.custom-radio {
    .custom-control-indicator {
        border-radius: 50%;
    }
    .custom-control-input:checked ~ .custom-control-indicator {
        background-image: url("data:image/svg+xml;
        charset=utf8,%3Csvg xmlns='http: //www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
    }
}
.custom-controls-stacked {
    display: flex;
    flex-direction: column;
    .custom-control {
        margin-bottom: 0.25rem;
        + .custom-control {
            margin-left: 0;
        }
    }
}

.custom-file {
    position: relative;
    display: inline-block;
    max-width: 100%;
    height: 2.5rem;
    margin-bottom: 0;
}
.custom-file-input {
    min-width: 14rem;
    max-width: 100%;
    height: 2.5rem;
    margin: 0;
    opacity: 0;
    &:focus ~ .custom-file-control {
        box-shadow: 0 0 0 0.075rem #fff,0 0 0 0.2rem #007bff;
    }
}
.custom-file-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 0.35rem;
    box-shadow: inset 0 0.2rem 0.4rem rgba(0,0,0,0.05);
    &:lang(en):empty::after {
        content: "Choose file...";
    }
    &::before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        z-index: 6;
        display: block;
        height: 2.5rem;
        padding: 0.5rem 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #e9ecef;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 0 0.35rem 0.35rem 0;
    }
    &:lang(en)::before {
        content: "Browse";
    }
}
